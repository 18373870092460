<script>
import { ValidationObserver } from 'vee-validate'
import { Pipeline } from '/~/core/pipeline'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useCms } from '/~/composables/cms/use-cms'

export default {
  name: 'eh-authority-form-modal',
  components: {
    BaseInput,
    BaseButton,
    BaseMdl,
    ValidationObserver,
  },
  setup() {
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { validationObserverRef } = useForm()
    const { ewalletLabels } = useCms()

    return {
      backendErrors,
      processBackendErrors,
      validationObserverRef,
      ewalletLabels,
    }
  },
  data() {
    return {
      form: {},
      processing: false,
      success: false,
    }
  },
  computed: {
    amountMask() {
      return {
        numeral: true,
        numeralPositiveOnly: true,
      }
    },
  },
  methods: {
    async onSubmit() {
      this.processing = true
      this.backendErrors = {}

      try {
        await Pipeline.submit('ehpayslipauth', this.form)
        this.success = true
      } catch (error) {
        this.processBackendErrors(error)
        this.$emit('error', error)
      } finally {
        this.processing = false
      }
    },
  },
}
</script>

<template>
  <base-mdl fullscreen="mobile">
    <template #title>
      <div>
        <p>Loyalty Program</p>
        <p class="text-sm">Authority to deduct (from payroll)</p>
      </div>
    </template>
    <validation-observer
      v-if="!success"
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form class="my-6" @submit.prevent="handleSubmit(onSubmit)">
        <base-input
          v-model="form.amount"
          :validation="{
            rules: 'required',
            name: 'Amount',
          }"
          :mask="amountMask"
          :error="backendErrors.amount"
          label="Amount per paycycle (AU$)"
          name="amount"
          currency
          clearable
        />
        <base-button
          class="grow rounded-none"
          type="submit"
          :disabled="processing"
          :loading="processing"
        >
          Submit
        </base-button>
      </form>
    </validation-observer>
    <template v-else>
      <transition name="fade">
        <div class="my-6 bg-slate-200 p-6">
          <h2>Success</h2>
          <p>Your authority to deduct from payroll have been submitted.</p>
        </div>
      </transition>
    </template>
    <p class="text-md">
      <small>
        Note: Payments will be made directly to EonX and lodged to
        {{ ewalletLabels.yourEwallet }}
        on Thursday after each pay day. Funds will be available on the following
        day (Friday).
      </small>
    </p>
  </base-mdl>
</template>
